// ====== COMMON LAYOUTS  ========

.item-form {
  width: 100%;

  label.error {
    color: @red;
  }

  label.validate {
    color: @green;
  }

  .field {
    background-color: @white;
    height: 44px;
    margin-top: 5px;
    border: 1px solid @primary;
    border-radius: 1px;

    &.error {
      border: 1px solid @red;

      svg {
        fill: @red;
      }

      input {
        border-left: 1px solid @red;
        color: @red;
      }
    }

    &.disabled {
      opacity: 0.3;
    }

    svg {
      height: 24px;
      padding-left: 10px;
      fill: @primary;
    }

    input {
      margin-left: 10px;
    }
  }

  svg:last-child {
    margin-right: 10px;
    height: 24px !important;
    cursor: pointer;
    fill: @primary;
  }

  p.tip {
    margin-top: 5px;
    opacity: 0.5;
    .font-size(0.875rem);
    line-height: 20px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &.error {
      color: @red;
      opacity: 1;
    }
  }
}

.explanation {
  @media @large-desktop {
    margin-left: 30px;
  }

  svg {
    height: 40px;
    fill: @white;
    margin-right: 15px;
  }

  p {
    width: 100%;

    @media @desktop {
      padding-left: 15px;
      border-left: 1px solid @primary;
    }

    @media @large-desktop {
      .font-size(1rem);
      line-height: 27px;
      width: 70%;
    }
  }
}

input[type='text'],
input[type='password'],
input[type='email'] {
  height: 24px;
  width: 100%;
  line-height: 22px;
  .font-size(0.875rem);
  padding-left: 10px;
  border: none;
  box-shadow: none;
  border-left: 1px solid @primary;
  font-family: 'Roboto', serif;

  &::placeholder {
    color: @primary-transparent;
  }
}

input[type='checkbox'] {
  .size(16px);
  cursor: pointer;
}

section {
  position: relative;
  width: 90%;
  background: @black-transparent;
  border: 1px solid @primary;
  border-radius: 7px;

  @media @tablette {
    max-width: 1200px;
    width: 90%;
  }
}

section.view {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-position: center !important;
  background-size: cover !important;
  background-attachment: fixed;
  overflow-y: auto;
  padding-top: 70px;
  border: none;
  border-radius: 0;
}

.full-separator {
  width: 100%;
  margin: 30px auto;
  height: 1px;
  border-top: 1px solid @primary;
}

.separator {
  width: 60%;
  margin: 15px auto;
  height: 1px;
  border-top: 1px solid @primary;
}

ul.pages {
  .display(flex);
  .align-items(center);
  .justify-content(space-evenly);

  width: 100%;
  border-top: 1px solid @primary;
  padding-top: 10px;
  margin: 40px auto auto auto;

  @media @tablette {
    width: 60%;
    .justify-content(center);
  }

  svg {
    margin-right: 20px;
    height: 18px;
    fill: @orange;
    cursor: pointer;

    &:last-of-type {
      transform: rotate(180deg);
      margin-left: 20px;
      margin-right: 0;
    }
  }

  li {
    margin: 5px;
    .size(30px);
    cursor: pointer;
    background: @primary-transparent;
    border: 1px solid @yellow-transparent;
    padding: 5px;
    .font-size(0.875rem);
    opacity: 0.5;
    text-align: center;

    @media @tablette {
      .size(auto);
    }

    &.active {
      color: @white;
      font-weight: bold;
      .font-size(1rem);
      opacity: 1;
    }

    @media @tablette {
      &:not(.active):hover {
        color: @yellow;
      }
    }

    svg {
      height: 18px;
      fill: @white;
    }

    .reverse {
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);

      @media @tablette {
        -ms-transform: scale(1, 1);
        transform: scale(1, 1);
      }
    }
  }
}

.pagination {
  margin: 10px auto;

  div {
    .size(10px);
    border-radius: 50%;
    border: 1px solid @black;
    margin-right: 10px;

    &.actif {
      background-color: black;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.section-title {
  .display(flex);
  .justify-content(space-between);
  margin: @small-margin;
  padding-top: 10px;

  div:first-child,
  div:last-child {
    border-top: 1px solid @primary;
    width: 15%;

    @media @tablette {
      width: 20%;
    }
  }

  label {
    .font-size(1.25rem);
  }
}

.bank-hud .slots .slot-item,
.inventory .slot-item {
  margin: 15px 8px auto 8px;
}

.icon {
  .display(flex);
  .align-items(center);
  .justify-content(center);

  svg {
    height: 26px;
    fill: @yellow;
    opacity: 0.4;
  }
}

&::-webkit-scrollbar {
  width: 4px;
}

&::-webkit-scrollbar-thumb {
  background-color: @primary-transparent;
  border-radius: 8px;
}
