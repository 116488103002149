@mobile: ~'only screen and (min-width: 360px)';
@mobile-landscape: ~'only screen and (min-width: 480px)';
@tablette: ~'only screen and (min-width: 768px)';
@desktop: ~'only screen and (min-width: 1224px)';
@large-desktop: ~'only screen and (min-width: 1680px)';

@retina: ~'(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)';

.mobile {
  @media @tablette {
    display: none;
  }
}

.tablette {
  display: none;

  @media @tablette {
    display: block;
  }
}

.desktop {
  display: none;

  @media @desktop {
    display: block;
  }
}

.large-desktop {
  display: none;

  @media @large-desktop {
    display: flex;
  }
}
