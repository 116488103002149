/*
.rem-mixins-test-class {
    .font-size(10px);
    .line-height(20px);
    .margin(10px);
    .margin(3em);
    .margin(10px, 20px);
    .margin(10px, 20%);
    .margin(0, 20px);
    .margin-top(2%);
    .margin-top(2px);
    .padding(10px);
    .padding(3em);
    .padding(10px, 20px);
    .padding(10px, 20%);
    .padding(3em, 20px)
}
*/
* {
  box-sizing: border-box;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
input {
  -webkit-user-select: text;
  /* Safari */
  -moz-user-select: text;
  /* Old versions of Firefox */
  -ms-user-select: text;
  /* Internet Explorer/Edge */
  user-select: text;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
p,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 0.9375rem;
  text-overflow: ellipsis;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  vertical-align: baseline;
  color: white;
}
@media only screen and (min-width: 1680px) {
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  p,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video,
  button {
    font-size: 1rem;
  }
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}
body {
  touch-action: manipulation;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
*:focus {
  outline: none;
}
.uppercase {
  text-transform: uppercase;
}
svg {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
}
h1 {
  font-size: 1.375rem;
  font-weight: 400;
}
h2 {
  font-size: 1.125rem;
  font-weight: 400;
}
h3 {
  font-size: 1rem;
  font-weight: 400;
}
p {
  font-size: 1rem;
  font-weight: 300;
}
.bold {
  font-weight: 700;
}
.orange {
  color: #9f620e;
}
.primary-color {
  color: #9f620e;
  fill: #9f620e;
}
.item-form {
  width: 100%;
}
.item-form label.error {
  color: #ff4141;
}
.item-form label.validate {
  color: #28b436;
}
.item-form .field {
  background-color: white;
  height: 44px;
  margin-top: 5px;
  border: 1px solid #9f620e;
  border-radius: 1px;
}
.item-form .field.error {
  border: 1px solid #ff4141;
}
.item-form .field.error svg {
  fill: #ff4141;
}
.item-form .field.error input {
  border-left: 1px solid #ff4141;
  color: #ff4141;
}
.item-form .field.disabled {
  opacity: 0.3;
}
.item-form .field svg {
  height: 24px;
  padding-left: 10px;
  fill: #9f620e;
}
.item-form .field input {
  margin-left: 10px;
}
.item-form svg:last-child {
  margin-right: 10px;
  height: 24px !important;
  cursor: pointer;
  fill: #9f620e;
}
.item-form p.tip {
  margin-top: 5px;
  opacity: 0.5;
  font-size: 0.875rem;
  line-height: 20px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.item-form p.tip.error {
  color: #ff4141;
  opacity: 1;
}
@media only screen and (min-width: 1680px) {
  .explanation {
    margin-left: 30px;
  }
}
.explanation svg {
  height: 40px;
  fill: white;
  margin-right: 15px;
}
.explanation p {
  width: 100%;
}
@media only screen and (min-width: 1224px) {
  .explanation p {
    padding-left: 15px;
    border-left: 1px solid #9f620e;
  }
}
@media only screen and (min-width: 1680px) {
  .explanation p {
    font-size: 1rem;
    line-height: 27px;
    width: 70%;
  }
}
input[type='text'],
input[type='password'],
input[type='email'] {
  height: 24px;
  width: 100%;
  line-height: 22px;
  font-size: 0.875rem;
  padding-left: 10px;
  border: none;
  box-shadow: none;
  border-left: 1px solid #9f620e;
  font-family: 'Roboto', serif;
}
input[type='text']::placeholder,
input[type='password']::placeholder,
input[type='email']::placeholder {
  color: rgba(141, 100, 43, 0.6);
}
input[type='checkbox'] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
section {
  position: relative;
  width: 90%;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #9f620e;
  border-radius: 7px;
}
@media only screen and (min-width: 768px) {
  section {
    max-width: 1200px;
    width: 90%;
  }
}
section.view {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-position: center !important;
  background-size: cover !important;
  background-attachment: fixed;
  overflow-y: auto;
  padding-top: 70px;
  border: none;
  border-radius: 0;
}
.full-separator {
  width: 100%;
  margin: 30px auto;
  height: 1px;
  border-top: 1px solid #9f620e;
}
.separator {
  width: 60%;
  margin: 15px auto;
  height: 1px;
  border-top: 1px solid #9f620e;
}
ul.pages {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -moz-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  width: 100%;
  border-top: 1px solid #9f620e;
  padding-top: 10px;
  margin: 40px auto auto auto;
}
@media only screen and (min-width: 768px) {
  ul.pages {
    width: 60%;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}
ul.pages svg {
  margin-right: 20px;
  height: 18px;
  fill: #ff9021;
  cursor: pointer;
}
ul.pages svg:last-of-type {
  transform: rotate(180deg);
  margin-left: 20px;
  margin-right: 0;
}
ul.pages li {
  margin: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: rgba(141, 100, 43, 0.6);
  border: 1px solid rgba(230, 172, 18, 0.7);
  padding: 5px;
  font-size: 0.875rem;
  opacity: 0.5;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  ul.pages li {
    width: auto;
    height: auto;
  }
}
ul.pages li.active {
  color: white;
  font-weight: bold;
  font-size: 1rem;
  opacity: 1;
}
@media only screen and (min-width: 768px) {
  ul.pages li:not(.active):hover {
    color: #e6ac12;
  }
}
ul.pages li svg {
  height: 18px;
  fill: white;
}
ul.pages li .reverse {
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
@media only screen and (min-width: 768px) {
  ul.pages li .reverse {
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
.pagination {
  margin: 10px auto;
}
.pagination div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #707070;
  margin-right: 10px;
}
.pagination div.actif {
  background-color: black;
}
.pagination div:last-child {
  margin-right: 0;
}
.section-title {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 10px auto;
  padding-top: 10px;
}
.section-title div:first-child,
.section-title div:last-child {
  border-top: 1px solid #9f620e;
  width: 15%;
}
@media only screen and (min-width: 768px) {
  .section-title div:first-child,
  .section-title div:last-child {
    width: 20%;
  }
}
.section-title label {
  font-size: 1.25rem;
}
.bank-hud .slots .slot-item,
.inventory .slot-item {
  margin: 15px 8px auto 8px;
}
.icon {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.icon svg {
  height: 26px;
  fill: #e6ac12;
  opacity: 0.4;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(141, 100, 43, 0.6);
  border-radius: 8px;
}
@media only screen and (min-width: 768px) {
  .mobile {
    display: none;
  }
}
.tablette {
  display: none;
}
@media only screen and (min-width: 768px) {
  .tablette {
    display: block;
  }
}
.desktop {
  display: none;
}
@media only screen and (min-width: 1224px) {
  .desktop {
    display: block;
  }
}
.large-desktop {
  display: none;
}
@media only screen and (min-width: 1680px) {
  .large-desktop {
    display: flex;
  }
}
